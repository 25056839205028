import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ChromePicker } from "react-color";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Crash() {
  const [dados, setDados] = useState([]);
  const [colorzero, setColorzero] = useState("#000000");
  const [colorlow, setColorlow] = useState("#000000");
  const [colormid, setColormid] = useState("#000000");
  const [colorhigh, setColorhigh] = useState("#000000");
  const [showPickerzero, setShowPickerzero] = useState(false);
  const [showPickerlow, setShowPickerlow] = useState(false);
  const [showPickermid, setShowPickermid] = useState(false);
  const [showPickerhigh, setShowPickerhigh] = useState(false);
  const [midvalor, setMidvalor] = useState(2);
  const [highvalor, setHighvalor] = useState(10);
  const [menos, setMenos] = useState(0);
  const [settings, setSettings] = useState(false);
  const [viewSimples, setViewSimples] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
        fetchData();
      }, 2000);
      return () => clearInterval(intervalId);
  }, [fetchData, highvalor, midvalor, viewSimples, mudarView]);

  //define quando a mensagem do bot do telegram deve ser enviada
  function contaNum(data) {
    const dados2 = data.slice(0, 50);
    const dados3 = dados2.reverse();
    let menor = 0;

    for(let i = 0;i < dados3.length;i++){
        if(dados3[i].crash_point < 2){
            menor++
        }else{
            menor = 0;
        }
    }
    

    if(menor >= 5){
        let message = 'https://api.telegram.org/bot6264199743:AAGGAqCHEVcP7_g8BZU4KcO8Qu_VVn5DiZg/sendMessage?chat_id=-1001204751341&text=🟢 ' + menor + ' vezes seguidas abaixo de 2.00x';
        
            if(menor != menos){
                sendPrivateMesssage(message);
            }
        
        
    }
    setMenos(menor);
  }

  //envio da mensagem para o bot do telegram
  const sendPrivateMesssage = useCallback(async(message) => {

      try{        
          await axios.get(message)
                  .then(response => {
                      
                  })
                  .catch(error => {
                      console.error(error);
                  });
      }catch(error){
          console.log(error);
      }

  },[]);
  //busca as atualizações de resultados da blaze via api
  async function fetchData() {
    await axios.get('https://rifa-json.belechedev.com/crash.php');
    const resposta = await axios.get('https://rifa-json.belechedev.com/crash2.php');
    setDados(resposta.data);
    contaNum(resposta.data);
  }
  //envia os dados atualizados para a tabela settings
  const sendData = () => {
    const url = 'https://rifa-json.belechedev.com/settings.php';
  
    const data = {
      colorzero: colorzero,
      colorlow: colorlow,
      colormid: colormid,
      colorhigh: colorhigh,
      midvalor: midvalor,
      highvalor: highvalor,
    };

    console.log(data);
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post(url, JSON.stringify(data))
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };
  
  //inicializa a aplicação buscando o settings no banco de dados
  async function settingsData() {
    if(!settings){
      const resposta = await axios.get('https://rifa-json.belechedev.com/settings.php');
      setColorzero(resposta.data[0].corzero);
      setColorlow(resposta.data[0].corlow);
      setColormid(resposta.data[0].cormid);
      setColorhigh(resposta.data[0].corhigh);
      setMidvalor(resposta.data[0].midnumber);
      setHighvalor(resposta.data[0].highnumber);
      setSettings(true);
    }
  }

  settingsData();

  function formatDiv(id, crash, date) {
    let classname = 'crash_point';
    let cbackground = colorzero;
    
    if(crash == 0){
        classname = 'crash_point crash_zero';
        cbackground = colorzero;
    }    
    if(crash > 0){
        classname = 'crash_point crash_low';
        cbackground = colorlow;
    }  
    if(crash >= midvalor){
        classname = 'crash_point crash_mid';
        cbackground = colormid;
    }
    if(crash >= highvalor){
      classname = 'crash_point crash_high';
      cbackground = colorhigh;
    }

    return <div key={id} className='crash_num'>
        <div className='crash_date'>{moment(date).format('DD/MM HH:mm:ss')}</div><div className={classname} style={{backgroundColor: cbackground}}>{crash}</div>
        </div>;
    
  }
  function formatDiv2(id, crash, date) {
    let classname = 'crash_point';
    let cbackground = colorzero;
    
    if(crash == 0){
        classname = 'crash_point crash_zero';
        cbackground = colorzero;
    }    
    if(crash > 0){
        classname = 'crash_point crash_low';
        cbackground = colorlow;
    }  
    if(crash >= midvalor){
        classname = 'crash_point crash_mid';
        cbackground = colormid;
    }
    if(crash >= highvalor){
      classname = 'crash_point crash_high';
      cbackground = colorhigh;
    }

    return <OverlayTrigger
    key={id}
    placement="top"
    overlay={<Tooltip id={`tooltip-bottom`}>{date}</Tooltip>}
    overlayClassName="tooltip-top"
    overlayInnerClassName="tooltip-content">
    <div className={classname} style={{backgroundColor: cbackground}}>{crash}</div></OverlayTrigger>;
    
  }
  //funções relacionadas as mudanças de cor
  const handleChangeCompletezero = (newColor) => {
    setColorzero(newColor.hex);
    setShowPickerzero(false);
  };
  const handleChangeCompletelow = (newColor) => {
    setColorlow(newColor.hex);
    setShowPickerlow(false);
  };
  const handleChangeCompletemid = (newColor) => {
    setColormid(newColor.hex);
    setShowPickermid(false);
  };
  const handleChangeCompletehigh = (newColor) => {
    setColorhigh(newColor.hex);
    setShowPickerhigh(false);
  };
  //----------------------------------------------

  function exportToExcel(data) {
    // criar um workbook
    const workbook = new ExcelJS.Workbook();
  
    // adicionar uma planilha
    const worksheet = workbook.addWorksheet('Dados');
  
    // adicionar cabeçalho
    worksheet.columns = [
      { header: 'Crash Point', key: 'crash_point' },
      { header: 'Date', key: 'date' },
    ];
  
    // adicionar os dados
    data.forEach(item => {
      worksheet.addRow(item);
    });
  
    // criar o arquivo Excel
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'dados.xlsx');
    });
  }

  const handleMidvalorChange = (event) => {
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue) && newValue >= 0) {
      setMidvalor(newValue);
    }
  };

  const handleHighvalorChange = (event) => {
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue) && newValue >= 0 && newValue >= midvalor) {
      setHighvalor(newValue);
    }
  };

  function mudarView() {
    if(viewSimples){
      setViewSimples(false);
    }else{
      setViewSimples(true);
    }
  };  
  


  return (
    <div style={{ columnCount: 1 }}>
      <div className='admPanel'>
        <div>
            <div className='color-inline'>ZERO</div>
            <div className='color-inline'
                style={{
                width: '10px',
                height: '10px',
                backgroundColor: colorzero,
                cursor: 'pointer',
                }}
                onClick={() => setShowPickerzero(!showPickerzero)}
            />
            <div className='color-inline'> = 0</div>
            {showPickerzero && (
            <div style={{ position: 'absolute', zIndex: 1 }}>
            <ChromePicker color={colorzero} onChangeComplete={handleChangeCompletezero} />
            </div>
            )}
        </div>
        <div>
            <div className='color-inline'>LOW</div>
            <div className='color-inline'
                style={{
                width: '10px',
                height: '10px',
                backgroundColor: colorlow,
                cursor: 'pointer',
                }}
                onClick={() => setShowPickerlow(!showPickerlow)}
            />
            <div className='color-inline'> {'>'} 0</div>
            {showPickerlow && (
            <div style={{ position: 'absolute', zIndex: 1 }}>
            <ChromePicker color={colorlow} onChangeComplete={handleChangeCompletelow} />
            </div>
            )}
        </div>
        <div>
            <div className='color-inline'>MID</div>
            <div className='color-inline'
                style={{
                width: '10px',
                height: '10px',
                backgroundColor: colormid,
                cursor: 'pointer',
                }}
                onClick={() => setShowPickermid(!showPickermid)}
            />
            <div className='color-inline'> {'>='} <input type="number" value={midvalor} onChange={handleMidvalorChange} /></div>
            {showPickermid && (
            <div style={{ position: 'absolute', zIndex: 1 }}>
            <ChromePicker color={colormid} onChangeComplete={handleChangeCompletemid} />
            </div>
            )}
        </div>
        <div>
            <div className='color-inline'>HIGH</div>
            <div className='color-inline'
                style={{
                width: '10px',
                height: '10px',
                backgroundColor: colorhigh,
                cursor: 'pointer',
                }}
                onClick={() => setShowPickerhigh(!showPickerhigh)}
            />
            <div className='color-inline'> {'>='} <input type="number" value={highvalor} onChange={handleHighvalorChange} /></div>
            {showPickerhigh && (
            <div style={{ position: 'absolute', zIndex: 1 }}>
            <ChromePicker color={colorhigh} onChangeComplete={handleChangeCompletehigh} />
            </div>
            )}
        </div>
        <div><button onClick={sendData}>Salvar dados</button></div>
        <div><button onClick={() => exportToExcel(dados)}>Exportar para Excel</button></div>
        <div>Menor que 2: {menos}</div>
        <div><button onClick={() => mudarView()}>Mudar View</button></div>
      </div>      
      {  viewSimples ?     
      <div className='crash'>
          {dados.map((item) => (
            formatDiv(item.id, item.crash_point, item.date)
          ))}
      </div> :
      <div className='crash_simples'>
          {dados.map((item) => (
            formatDiv2(item.id, item.crash_point, item.date)
          ))}
      </div> 
      }
    </div>
  );
}

export default Crash;